import React, { useEffect, useRef } from 'react';
import Layout from '../components/layout';
import WebinarRegistrationPopup from '../components/Popup/webinar-registration-popup';
import SEO from "../components/seo";
import IPProtectionImg from '../images/aws-openai-soc.jpg';
import '../styles/webinar-page.css';

const WebinarMeetsIP = () => {
  const WEBINAR_TITLE = 'AI-IP Fusion in 2025: I Want To Show You An Insane Patent Drafting Tool!';
  const seo = {
    metaDesc: `${WEBINAR_TITLE} - Inventiv.org`
  }
  const WEBINAR_AI_MEETS = "https://webinarkit.com/webinar/registration/oneclick/679b4308f04c6f4971b0e9bb?date=jit_15";
  const childRef = useRef();
  const openPopup = () => {
    //
    childRef.current?.open(WEBINAR_AI_MEETS);
  }
  //
  useEffect(() => {
    document?.body.classList.add('no-home');
    //
  }, []);

  return (
    <Layout activeMenu={'webinar'}>
      <SEO title={`Webinar ${WEBINAR_TITLE} - Inventiv.org`} canonical='/webinar-ai-meets-ip-welcome-to-the-future-of-patent-drafting' seo={seo} />

      <div class="sub-banner" style={{ height: 'auto', backgroundColor: '#0C6873' }}>
        <div class="container py-5">
          <div class="text-center">
            <h1 className='text-white'> AI PATENT DRAFTING IN ACTION </h1>
            <h6 className='text-white'>Presented by Bao Tran, Founder of PowerPatent</h6>
          </div>
        </div>
      </div>

      {/* <!-- Rsvp start --> */}
      <div class="rsvp content-area-2" id="rsvp">
        <div class="container px-5">
          <div className='px-5'>
            <div class="row">
              <div class="col-12 wow fadeInUp delay-04s">
                <div class="row">
                  <h1 className="text-center bold">{WEBINAR_TITLE}</h1>
                  <h5 className='text-center'>Created By Patent Lawyers, For Patent Lawyers. </h5>
                </div>
              </div>
            </div>
            <div className='my-5'>
              <div className='row'>
                <div class=" text-center col-md-6 wow fadeInUp delay-04s">
                  <div className='circular_image'>
                    <img style={{ display: 'block', objectFit: 'cover' }} src='https://webinarkituploads.s3.us-east-2.amazonaws.com/user%2F6747df2bfba398dfcc5f14e8%2Fmohamed-nohassi-tdu54W07_gw-unsplash.jpg' alt='An AI-Powered Patent Drafting Solution'></img>
                  </div>
                  <h5 className='bold mt-2'>Instant watch session available. Join now!</h5>
                </div>
                <div class="col-md-6 wow fadeInUp delay-04s">
                  <div className='my-element'>
                    <h3 className='bold text-center'>Here's what you'll learn:</h3>
                    <div className='d-flex justify-content-start my-2'>
                      <h5 className='main-clr'>✓</h5>
                      <h5 className='ms-3'>Practical walkthrough: AI <strong>patent drafting</strong> in action</h5>
                    </div>

                    <div className='d-flex justify-content-start my-2'>
                      <h5 className='main-clr'>✓</h5>
                      <h5 className='ms-3'>Avoiding common <strong>patent pitfalls</strong> with AI</h5>
                    </div>

                    <div className='d-flex justify-content-start my-2'>
                      <h5 className='main-clr'>✓</h5>
                      <h5 className='ms-3'>Understanding the limits and <strong>ethics</strong> of AI in patents</h5>
                    </div>

                    <div className='d-flex justify-content-start my-2'>
                      <h5 className='main-clr'>✓</h5>
                      <h5 className='ms-3'><strong>AI's revolutionary impact</strong> on patent drafting</h5>
                    </div>
                    <div>
                      <a href="#" onClick={e => { e.preventDefault(); openPopup()}} class="btn btn-default my-3" style={{ width: '100%' }}>Watch Webinar Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container px-5'>
        <div class="d-flex justify-content-center">
          <img src={IPProtectionImg} alt='Our lawyers have worked on IP protection for' />
        </div>
      </div>
      <WebinarRegistrationPopup ref={childRef}></WebinarRegistrationPopup>
      {/* <!-- Rsvp end --> */}
    </Layout>
  )
};

export default WebinarMeetsIP;